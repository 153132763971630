<template>
  <div>
    <el-dropdown @command="command">
      <el-button type="primary">
        Actions <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, i) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :icon="action.icon"
            :key="i"
            :command="{
              command: action.command,
              component: action.component,
            }"
          >
            {{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :action="action.command"
      @success="$emit('action')"
    />
  </div>
</template>

<script>
export default {
  name: "OrdersActions",
  components: {
    OrdersExport: () => import("./OrdersExport"),
    OrdersStatusChange: () => import("./OrdersStatusChange"),
    OrdersReschedule: () => import("./OrdersReschedule"),
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      actions: [
        {
          access: ["admin", "gardener", "operations"],
          icon: "eden-icon-upload",
          label: "Export Orders",
          command: "export",
          component: "orders-export",
        },
        {
          access: ["admin", "operations"],
          icon: "el-icon-video-pause",
          label: "Pause Orders",
          command: "pause",
          component: "orders-status-change",
        },
        {
          access: ["admin", "operations"],
          icon: "el-icon-video-play",
          label: "Unpause Orders",
          command: "unpause",
          component: "orders-status-change",
        },
        {
          access: ["admin", "operations"],
          icon: "eden-icon-calendar",
          label: "Reschedule Orders",
          command: "reschedule",
          component: "orders-reschedule",
        },
      ],
    };
  },
  methods: {
    command(command) {
      this.action.command = command.command;
      this.action.component = command.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
  },
};
</script>
